const svtB1Meta: PageMetaData = {
  title: 'Being a Vampire',
  description: 'Being a Vampire - Seattle Vampire Tales Book One',
  ogTitle: 'Being a Vampire',
  ogDescription: 'Being a Vampire - Seattle Vampire Tales Book One',
  ogImage: 'https://www.ramonaridgewell.com/img/SVT-B1-website-meta-data.png',
  ogUrl: 'https://www.ramonaridgewell.com/svt-b1-being-a-vampire',
  ogType: 'website',
}

const svtB2Meta: PageMetaData = {
  title: 'Strength of a Vampire',
  description: 'Strength of a Vampire - Seattle Vampire Tales Book Two',
  ogTitle: 'Strength of a Vampire',
  ogDescription: 'Strength of a Vampire - Seattle Vampire Tales Book Two',
  ogImage: 'https://www.ramonaridgewell.com/img/SVT-B2-website-meta-data.png',
  ogUrl: 'https://www.ramonaridgewell.com/svt-b2-strength-of-a-vampire',
  ogType: 'website',
}

const svtB3Meta: PageMetaData = {
  title: 'Blood of a Vampire',
  description: 'Strength of a Vampire - Seattle Vampire Tales Book Two',
  ogTitle: 'Strength of a Vampire',
  ogDescription: 'Strength of a Vampire - Seattle Vampire Tales Book Two',
  ogImage: 'https://www.ramonaridgewell.com/img/SVT-B3-website-meta-data.png',
  ogUrl: 'https://www.ramonaridgewell.com/svt-b3-blood-of-a-vampire',
  ogType: 'website',
}

export const HomeMeta: PageMetaData = {
  title: 'Ramona Ridgewell Author',
  description: 'Ramona Ridgewell Author Home page',
  ogTitle: 'Ramona Ridgewell Author',
  ogDescription: 'Ramona Ridgewell Author Home page',
  ogImage: 'https://www.ramonaridgewell.com/img/Headshot-website-meta-data.png',
  ogUrl: 'https://www.ramonaridgewell.com/',
  ogType: 'website',
}

export const DefaultMeta: PageMetaData = {
  title: 'Ramona Ridgewell Author',
  description: 'Ramona Ridgewell Author page',
  ogTitle: 'Ramona Ridgewell Author',
  ogDescription: 'Ramona Ridgewell Author page',
  ogImage: 'https://www.ramonaridgewell.com/img/Headshot-website-meta-data.png',
  ogUrl: 'https://www.ramonaridgewell.com/',
  ogType: 'website',
}

export  type PageMetaData = {
  title: string;
  description: string;
  ogTitle: string;
  ogDescription: string;
  ogImage: string;
  ogUrl: string;
  ogType: string;
}

export const getPageMetaData = (path: string) => {
	switch (path) {
    case '/svt-b3-blood-of-a-vampire':
      return svtB3Meta
    case '/svt-b2-strength-of-a-vampire':
      return svtB2Meta
    case '/svt-b1-being-a-vampire':
      return svtB1Meta
    case '/':
    case '/home':
      return HomeMeta
    default:
      return DefaultMeta
	}
}
